<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">

        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdTime)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.createdTime | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                {{data.item.isActive?'AKTİF':'PASİF'}}
            </b-badge>
        </template>

        <!-- Actions -->
        <template #cell(actions)="data">
            <b-button variant="flat-primary" @click="openDetails(data.item)">
                <feather-icon icon="ZoomInIcon" class="mr-50" />
                <span class="align-middle">Önizleme</span>
            </b-button>
            <b-button variant="flat-warning" @click="editPage(data.item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Düzenle</span>
            </b-button>
        </template>

    </b-table>

    <!--Open Add Page-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" width="700px" shadow backdrop no-header right v-model="addPagePanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Sayfa Ekle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Sayfa Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addPageRequest.name" />
                </b-form-group>
                <b-form-group label="Sayfa Detayları" label-for="name" style="height:550px">
                    <quill-editor v-model="addPageRequest.definition" style="height:400px" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addPage">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>

    <!--Open Edit Page-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" width="700px" shadow backdrop no-header right v-model="editPagePanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Sayfa Ekle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Sayfa Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editPageRequest.name" />
                </b-form-group>
                <b-form-group label="Sayfa Detayları" label-for="name" style="height:500px">
                    <quill-editor v-model="editPageRequest.definition" style="height:400px" />
                </b-form-group>
                <b-form-group label="Yayınlama" label-for="from">
                    <b-form-checkbox v-model="editPageRequest.isActive">
                        Öğrencilere Yayınlansın
                    </b-form-checkbox>
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updatePage">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>

</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker
} from 'bootstrap-vue'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        ToastificationContent,
        quillEditor,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BFormDatepicker
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdTime', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'name', label: 'Sayfa Adı' },
                { key: 'actions', label: 'Önizleme' },
            ],
            editorOption: {
                modules: {
                    toolbar: '#toolbar',
                },
            },
            users: [],
            addPagePanel: false,
            addPageRequest: {
                videoGroupId: 0,
                name: '',
                definition: ''
            },
            editPagePanel: false,
            editPageRequest: {
                pageId: "0",
                name: '',
                definition: '',
                isActive: false
            },
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addPagePanel = true;
        });
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var users = await this.$http.get("Teacher/Pages/" + this.$route.params.videoGroupId);
            this.users = users.data.data;
        },
        editPage(data) {
            this.editPageRequest = {
                pageId: data.id,
                name: data.name,
                definition: data.definition,
                isActive: data.isActive
            };
            this.editPagePanel = true;
        },
        openDetails(item) {
            var myWindow = window.open("", "Önizleme", 'fullscreen="yes"');
            myWindow.document.write(item.definition);
        },
        addPage() {
            this.addPageRequest.videoGroupId = this.$route.params.videoGroupId;
            var request = this.addPageRequest;
            this.$http.post("Teacher/AddPages", request).then((data) => {
                    this.getData();
                    this.addPagePanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Sayfa Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        updatePage() {
            var request = this.editPageRequest;
            this.$http.put("Teacher/UpdatePages", request).then((data) => {
                    this.getData();
                    this.editPagePanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Sayfa Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
    }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
